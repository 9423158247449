.searchName{
    margin-top: 12%;
}

.btnSearch{
    margin-top: 4%;
    height: 40px;
}

.infoAlert{
    margin: 2% 0 2% 0;
}

.printButtonReport{
    float: right;
    width: 18%;  
    margin-inline-end: 1%;
}


.printButtonReport2{
    float: right;
    width: 28%;  
}

.btnHeight {
    height: 35px;
}