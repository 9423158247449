.cont{
    margin-top: 90px;
    margin-left: 50px;
    margin-right: 50px;
}

.btnW{
    font-size: x-large;
}

.user-img {
  max-width: 160px; /* Ancho máximo */
  max-height: 160px; /* Altura máxima */
  border-radius: 50%;
  text-align: center;
  border: 3px solid #d2d6de;
  padding: 3px;
  border-radius: 50%;
  vertical-align: center;
}


.supMargin{
    margin-top: 2%;
}

thead th {
    font-family: 'Inter', sans-serif;
    background-color: #dcdcdc !important
}

.customTbl{
    border-color: white;
}

.btnEditar{
    background-color: rgb(28, 153, 194);
    color: black;
    border-color:rgb(28, 153, 194);
}

.btnPago{
    background-color: rgb(245, 137, 49);
    color: black;
    border-color:rgb(245, 137, 49);
}

.btnCancelar{
    background-color: rgb(227, 69, 40);
    color: black;
    border-color:rgb(227, 69, 40);
}

.btnMonthTrans{
  background-color: rgb(103, 153, 223);
  color: black;
  border-color:rgb(103, 153, 223);
}

.btnReactivar{
    background-color: rgb(40, 227, 140);
    color: black;
    border-color:rgb(40, 227, 140);
}



.checkbox-container {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en varias líneas si es necesario */
  justify-content: space-between; /* Espacio entre los elementos */
}

.checkbox-item {
  text-align: center; /* Centra el contenido horizontalmente */
  display: flex;
  flex-direction: column; /* Los elementos se colocarán en columna vertical */
  align-items: center; /* Centra los elementos horizontalmente */
  margin-right: 20px; /* Espacio entre los elementos (puedes ajustar según tu preferencia) */
  width: calc(33.33% - 20px); /* Ancho deseado para cada elemento (33.33% para 3 elementos en una fila) */
}

.container-check {
  text-align: center; /* Centra el contenido horizontalmente */
  --input-focus: #2d8cf0;
  --input-out-of-focus: #ccc;
  --bg-color: #fff;
  --bg-color-alt: #666;
  --main-color: #323232;
  position: relative;
  cursor: pointer;
}

/* Añade estilos al texto si es necesario */
.container-check span {
  display: block; /* Hace que el texto se muestre en una línea separada */
}

.container-check input {
  position: absolute;
  opacity: 0;
}

.checkmark {
  width: 40px;
  height: 40px;
  position: relative;
  top: 0;
  left: 0;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  box-shadow: 1px var(--main-color);
  background-color: var(--input-out-of-focus);
  transition: all 0.3s;
}

.container-check input:checked ~ .checkmark {
  background-color: var(--input-focus);
}

.checkmark:after {
  content: "";
  width: 15px;
  height: 24px;
  position: absolute;
  top: 2px;
  left: 9px;
  display: none;
  border: solid var(--bg-color);
  border-width: 0 2.5px 2.5px 0;
  transform: rotate(45deg);
}

.container-check input:checked ~ .checkmark:after {
  display: block;
}

@media (max-width: 768px) {
  .checkbox-item {
    width: 100%; /* Hace que los elementos ocupen todo el ancho en dispositivos pequeños */
    margin-right: 0; /* Elimina el margen en dispositivos pequeños */
  }
}
