@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@1,400;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.offcanvasB{
    padding: 5px;
}

.btnToggler{
    cursor: pointer;
}

.title{
    font-size: larger;
    color: black;
}

.subtitle{
    font-size: medium;
    color: black;
}

.accordion-button{
    font-size: large;
    color: black;
}

footer{
    padding:15px; 
    font-size:14px; 
    border-top:1px solid #d2d6de; 
    background:#fff;
}

/************NavBar**********/

#main-header-nb{
    position: fixed;
    z-index: 100;
    background-color: white;
    width: 100%;
}

#logo-nb{
    margin-top: -2.3vh;
    position: relative;
    margin-left: 50%;
    align-items: center;
}
@media (max-width: 482px) {
    #logo-nb{
        margin-left: 0%;
        margin-right: 10%;
        width: 50%;
    }
}

#ul-nb{
    display: flex;
}
@media (max-width: 482px) {
    #ul-nb{
        display: block;
        margin-bottom: 1vh;
        margin-left: -19vh;
    }
    #div-ml{
        margin-top: -0.7vh;
    }
    #simple-menu-ml{
        width:15vh;
        margin-top: 0.4vh;
    }
    
}
.link-nb{
    font-size: 14px;
    color: rgb(85,85,85);
    text-decoration: none;
    margin-top: 0.3vh;
}
.link-nb:hover{
    color: rgb(85,85,85);
}
/************NavBar**********/
/***************** FormLogin ***************/
#div-email-fl{
    padding-left:20px; 
    padding-right:20px;
    margin: 15px;
}
#div-password-fl{
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 15px 15px 15px;
}
.icon-form{
    padding-right: 3px;
}
#div-login-fl{
    padding-left:20px;
    padding-right:20px; 
    margin-bottom:15px;
}
#btn-login-fl{
    width:100%; 
    border-color:#ddd; 
    /* background-color:#f4f4f4; */ 
    box-shadow:none; 
    border-width:1px;
}
#div-rp-fl{
    padding: 10px;
    font-size: 14px;
    background-color: white;
}
.img-user-login{
    height: auto;
    margin: 0;
    padding: 3px;
    border-radius: 50%;
    text-align: center;
}
.img-user-login img{
    border-radius: 50%;
    background: #FFF;
}
.container-user-login{
    text-align: center;
    background-color: #e5e5e5;
}
.info-user-login{
    text-align: center;
    margin-top: 2vh;
}
input {
    padding: 0.5vh;
}

@media (max-width:425) {
    .hidden-xs{
        display: flex;
        flex-flow: nowrap;
    }
    
}

/***************** FormLogin ***************/

/*************MenuLogin***************/
#div-ml{
    margin: 0 1vw;
}
@media (max-width: 440px) {
    #div-ml{
        margin-top: -0.7vh;
    }
    
}
#simple-menu-ml{
    font-size: 14px;
    color: rgb(85,85,85);
    text-decoration: none;
    cursor: pointer;
    display:inline-block;
}
/*************MenuLogin***************/
/*********************Home*************/
.fila{
    display: flex;
    justify-content: center !important;
    align-items: center;
}

#div-content-h{
    display: flex;
    background-color: #f1f1f1;
    justify-content: center !important;
    align-items: center;

    
}
#div-container-h{
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
}
.card{
    display: flexbox;
    justify-content: center !important;
    width: 100%;
    font-size: 14px;
}
#div-card1-header-h{
    background-color: #f39c12;
    padding: 1vw;
}
#div-card1-header-h h5{
    margin-left: 4vw;
    color:white;
    font-weight: 300;
    font-size: 24px;
}
#div-card1-header-h h6{
    margin-left: 4vw;
    color:white;
    font-weight: 500;
}
#div-card2-header-h{
    background-color: #00a65a;
    padding: 1vw;
}
#div-card2-header-h h5{
    margin-left: 4vw;
    color:white;
    font-weight: 300;
    font-size: 24px;
}
#div-card2-header-h h6{
    margin-left: 4vw;
    color:white;
    font-weight: 500;
}
.pull-right{
    float: right;
}
#link-basic-h{
    background-color: #f39c12;
    width: 100%;
}
#link-pro-h{
    background-color: #00a65a;
    width: 100%;
}
#h4-col-h{
    font-size: 18px;
    font-weight: 500;
}
#div-hr-h{
    margin-top: 20px;
}
#div-col-md-6-h{
    font-size: 14px;
    font-weight: 400;
}
h3{
    font-size: 24px;
    font-weight: 500;
}
#div-contact-us-h{
    font-size: 14px;
}
iframe{
    border: 0;
}

/*********************Home*************/


/*********Carousel*********/
#Carousel-div{
    background-size: cover; 
    width: 100%; 
    height: 80vh;
}
@media (max-width: 600px) {
    #Carousel-div{
        margin-top: 11vh;
        height: 50vh;
    }
}
#Carousel-h1{
    color:white;
    padding-left: 10%;
    padding-top: 5%;
    font-size: 3.5vw;
    text-shadow: 1px 1px 5px #666;
}

/************Carousel**********/
#container-rp{
    min-height: 100vh;
    margin: auto;
}

#content-header-rp{
    padding-top: 10vh;
    font-size: 24px;
    font-weight: 500;
}
.content-header h1 {
    font-size: 24px;
    font-weight: 500;
}
.breadcrumbs{
    background: #E4E4E4;
    padding: 1vh;
    font-size: 0.7rem !important;
    float:right;
}
.link-rp{
    font-size: 12px;
    text-decoration: none; 
    color: #444;
}
.link-rp:hover{
    color: #444;
}

.icon{
    margin-right: 1vw;
}
#col-card-rp{
    margin-left: auto;
    margin-right: auto;
}
.content-btn-rp{
    margin-top: 15px;
    text-align: center;
}
#btn-rp{
    width: 100%;
}
hr{
    width: 100%;
}
h5{
    font-size: 18px;
    font-weight: 300;
}
.label_confpassw{
    margin-top: 2vh;
}
@media (max-width:600px) {
    #h1-grid{
        display: grid;
    }
}

/* Business info */

.div-form-bi{
    display: grid;
}
.div-form-bi label{
    margin-bottom: 2vh;
}
.div-form-bi input{
    margin-bottom: 2vh;
}
#btn1-bi {
    background-color: #f4f4f4;
    color: #444;
    border-color: #ddd;
    font-size: 12px;
}
#btn2-bi {
    background-color: #f4f4f4;
    color: #444;
    border-color: #ddd;
    font-size: 12px;
}

#certificado-bi{
    text-align: start;
    font-size: 12px;
}
#btn1-address{   
    font-size: 14px;
    margin-right: 0.2vw;
    margin-bottom: 1vh;
}
#btn2-address{
    font-size: 14px;
    
}
#btn3-address{
    font-size: 14px;
    margin: 0 0.2vw
}
#btn4-address{
    font-size: 14px;
}
@media (max-width: 1024px) {
    #btn1-address{   
        
    }
    #btn2-address{
        margin-bottom: 0.5vh;
        margin-right: 0.5vh;
    }
    #btn3-address{
        margin-bottom: 0.5vh;
        margin-right: 0.5vh;
    }
    #btn4-address{
        margin-bottom: 0.5vh;

    }
    
}
.div-agregarAddress{
    margin-top:2vh;
}
.btn-agregarAddress{
    background-color: white;
    margin-bottom: 2vh;
    padding: 0.5vw;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
#link-agregarAddress{
    font-size: 12px;
}
.form-group{
    margin-top: 2vh;
}
.form-group-files{
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
}
.container-files-fa{
    display: grid;
    grid-template-columns: repeat(2,50%);
}
#btn-addAddress{
    margin-top:2vh;
    background-color: #3c8dbc;
}
.card{
    margin-top: 2vh;
}

#nombre-profile{
    text-align: center;
}

.profile-user-img{
    width: 50%;
    border-radius: 50%;
    text-align: center;
    border: 3px solid #d2d6de ;
    height: auto;
    margin: 0;
    padding: 3px;
    border-radius: 50%;
    vertical-align: center;
}

.imagen-profile{
    text-align: center;
}
.list-group-item{
    border-left: 0;
    border-right: 0;
    /* font-family: 'Source Sans Pro'; */
}
.email-profile{
    float: right;
    width: 100%;
}
b{
}
.form-control{
    margin-bottom: 1.5vh;
}
.form-group-principales{
    margin-top: 1vh;
    text-align: center;
}
#btnPrincipal{
    margin-right: 1vh;
}
@media (max-width:989px) {
    #btnPrincipal{
        margin-bottom: 0.5vh;
    }
}
.form-group-secundarios{
    text-align: center;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-name: slides-top;
            animation-name: slides-top;
}
#btnSecundario{
    margin-right: 1vh;
}
#btnSecundario2{
    margin-right: 1vh;
}
#alePaswword{
    margin-top: 7vh;
}
#form-group-contraseñas{
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-name: slides-top;
            animation-name: slides-top;

}
#error{
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: error-animation;
            animation-name: error-animation;
}
.alert{
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-name: error-animation;
            animation-name: error-animation;
} 
@-webkit-keyframes error-animation {
    from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
} 
@keyframes error-animation {
    from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
}
@-webkit-keyframes slides-top {
    from {
        margin-top: 10%;
        width: 100%
      }
    
      to {
        margin-top: 0%;
        width: 100%;
      }
}
@keyframes slides-top {
    from {
        margin-top: 10%;
        width: 100%
      }
    
      to {
        margin-top: 0%;
        width: 100%;
      }
}
 a[href="#/"]{
     cursor: default;
 }


* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;

}

body {
    background: #F1F1F1;
    font-family: sans-serif;
    margin: 0px;
}

.navbar {

    background: #FFFFFF !important;
    background-size: cover;
    top: 0px;
    position: fixed;
    z-index: 100;
    height: 50px;
    width: 100%;
    float: left;
}

main {
    max-width: 1800px;
    width: 90%;
    margin: auto;

    flex-wrap: wrap;
    display: flex;
    justify-content: center;


}

.son {
    background: #FFFFFF;

    width: 90em;
    border: 2px solid #E8EAEE;
    padding-top: 3px;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
}

.son-hidden {
    opacity: 0.3;
}

.son1 {
    background: #FFFFFF;
    width: 90em;
    height: 41px;
    border: 1px solid #E8EAEE;

}

.son2 {
    display: flex;
    justify-content: space-between;
    margin: auto;
    -webkit-padding-end: 3%;
            padding-inline-end: 3%;
    margin-top: 5%;
    width: 90em;

}

.footer {
    background: #FFFFFF;
    width: auto;
    height: 50px;
    align-self: flex-end;

}

nav ul {
    list-style: none;
    overflow: hidden;

}

nav ul li {
    float: left;
    font-family: Arial, sans-serif;
    font-size: 16px;
}

.DivForm {
    display: flex;
}

@media (max-width: 380px) {
    .DivForm {
        display: block;

    }

    .form-select {
        margin-bottom: 1vh;
    }

}

.DivFormState {
    margin-left: 2%;
}

.DivFormCC {
    width: 50%;
    margin-right: 2%;
}

.breadcrumbs {
    background-color: #E4E4E4;
    padding: 1vh;
    font-size: 0.7rem !important;
    float: right;
}

.images-dashboard {
    width: 3.5vw;

}

.body-btn {
    display: flex;
}

.images-btn {
    margin-left: 1vw;
    text-align: center;
    color: #3c8dbc;
    border-radius: 5px;
    width: 6vw;
}

@media (max-width: 600px) {
    .images-btn {
        width: 10vh;
    }

    .images-btn img {
        width: 12vw
    }


}

@media (max-width: 768px) {
    .images-btn {
        width: 10vh;
    }

    .images-btn img {
        width: 12vw
    }

    #error {
        grid-column: span 1;
    }


}

@media (max-width: 979px) {
    .images-btn {
        width: 10vh;
    }

    .spinner {
        visibility: hidden;
    }

}

.images-btn:hover {
    border: 1px #d1d1d1 solid;
    background-color: #fafafa;
}

a {
    text-decoration: none;
}

hr {
    color: #E4E4E4;

}

#card-txt-AcC {
    text-align: center;
}

.inputMask {
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid #B6BFCF;

}

.inputMask:focus-visible {
    outline: none;
}

.inputMask:focus {
    border: 1px solid #3C8DBC;
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
}


.inputCcNumber {
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 34px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid #B6BFCF;
    margin-right: 2%;
}

.inputCcNumber:focus {
    border: 1px solid #3C8DBC;
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
}

.table {
    margin-top: 2%
}

#card-title-db {
    display: flex;
    justify-content: space-between;
}

.form-select {
    margin-right: 1vw;
}

#btn-db {
    font-size: 14px;
    width: 15vh;

}

#form-group-credit-card {
    -webkit-animation-duration: 1.2s;
            animation-duration: 1.2s;
    -webkit-animation-name: slides-top;
            animation-name: slides-top;
}

#form-group-debitcard {
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-name: slides-top2;
            animation-name: slides-top2;
}

#form-group-ath-movil {
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-name: slides-top2;
            animation-name: slides-top2;
    text-align: center;
}

#form-group-ath-movil-formAddress {
    display: grid;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-name: slides-top2;
            animation-name: slides-top2;
    text-align: center;
}

#ATHMovil_Checkout_Button {
    margin-top: 5vh;
}

.ATHMovil_disabled {
    opacity: 0.5;
}

#ATHMreopen-modal {
    position: fixed !important;
}

.ATHMovil_fake {
    position: relative;
    top: -94px;
    width: 92%;
    height: 10vh;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    border: none;
    color: none;
}

.disappear {
    display: none;
    position: relative;
    top: -94px;
    width: 92%;
    height: 10vh;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    border: none;
    color: none;
}

.QR_text {
    margin-top: 3vh;
    padding-bottom: -5vh;
}

#error {
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-name: error-animation;
            animation-name: error-animation;
}

.error-message {
    color: #FFEBEE;
}

.alert-formAdd {
    margin-top: 1vh
}

.fales {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.hhh {
    height: 100px !important;
    width: 100px !important;
}

.file-preview {
    font-size: 14px;
    width: 450px;
}

.sel {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #106BA0;
    display: inline-block;
    transition: all .5s;
    cursor: pointer;
    padding: 15px 40px !important;
    text-transform: uppercase;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    height: 80px;
    width: 260px;
    border-radius: 2%;
}

/* .spinner {
    position: absolute;
    left: 55vw;
    top: 55vh;
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    border-left-color: #0072BA;
  
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  } */

@-webkit-keyframes error-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes error-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@-webkit-keyframes slides-top {
    from {
        margin-left: 10%;
        width: 100%
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}


@keyframes slides-top {
    from {
        margin-left: 10%;
        width: 100%
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@-webkit-keyframes slides-top2 {
    from {
        margin-left: 10%;
        width: 100%
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@keyframes slides-top2 {
    from {
        margin-left: 10%;
        width: 100%
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}



/**/

/* Estilos personalizados para los números de página */
/* Estilos para los elementos de paginación */
.pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
    ;
    padding: 5px 7px;
    border-radius: 0;
    /* Elimina la redondez de los elementos */
    cursor: pointer;
}

/* Estilo para la página actual */
.pagination li.active {
    background-color: #b1b3b6;
    /* Color de fondo para la página actual */
    color: #fff;
    /* Color de texto para la página actual */
}


.pagination a {
    border-radius: 0% !important;
}


.button2 {

    height: 34px;
    line-height: 45px;
    width: 40%;
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: .1s ease all;
    padding-bottom: 37px;
    margin-right: 2%;

    &:hover {
        box-shadow: 3px 0px 30px rgba(163, 163, 163, 1);
    }
}






/* Estilo del spinner2 */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Fondo oscuro con un poco de transparencia */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Coloca el modal por encima del contenido */
}

/* Estilo para el spinner */
.spinner2 {
    width: 90px;
    height: 90px;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    -webkit-animation: spinner-e04l1k 1s infinite linear;
            animation: spinner-e04l1k 1s infinite linear;
  }

.spinner2::before,
.spinner2::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
}

.spinner2::before {
    border-color: #474bff #0000;
    -webkit-animation: inherit;
            animation: inherit;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    animation-direction: reverse;
}

.spinner2::after {
    margin: 8.9px;
}

.terms-text {
    font-size: 13px;           /* Tamaño de fuente legible */
    line-height: 1.6;          /* Espaciado entre líneas */
    color: #333;               /* Color oscuro para mejor contraste */
    text-align: justify;       /* Justificación para un aspecto ordenado */
}


.table {
    border-collapse: collapse;
    border: 1px solid black;
    text-align: center;
}

.tr1 {
    background-color: #616161;
    color: white;
    font-family: 'Inter', sans-serif;
}

.btn-descargar {
    background-color: #0D47A1;
    border-radius: 50%;
    border: 1px none;
    padding: 0.5vh;
    color: gainsboro
}

.mainn {
    max-width: 2000px;
    width: 99%;
    margin: auto;
    margin-top: 2vw;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    padding-top: 3%;

}

.sonn {
    background: #FFFFFF;

    width: 100em;
    border: 2px solid #E8EAEE;
    padding-top: 3px;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;


}

.struc {

    width: 100em;
    border: 2px solid #E8EAEE;

    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;


}

.sonn1 {
    background: #FFFFFF;
    width: 100em;
    height: 41px;
    border: 1px solid #E8EAEE;

}

.titulo {
    color: #333333;
    font-size: 33px;
    margin-left: 1%;
}

.butF {
    background-color: #0C77BC;
    width: 35px;
    height: 25px;
    display: flex;
    border: none;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10%;
    transition-duration: 0.4s;
    margin: 0 auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.editButton {
    border: none;
    background-color: white;
}

.icon1 {
    margin-left: 4%;
}

.butF:hover {
    background-color: #2297e6;
    color: white;
}

.icon {
    position: 50%;
}

tbody tr:nth-child(odd) {
    background-color: #F6F6F6;
    vertical-align: middle;
    align-items: center;

}

tbody tr:nth-child(even) {
    background-color: #DEDEDE;
    vertical-align: middle;
    align-items: center;
}

th {
    vertical-align: middle;
    align-items: center;
}

th.id {
    background-color: #616161;
    color: white;
    text-align: center;

}

table td:nth-child(6) {
    width: 120px;
}

table td:nth-child(7) {
    width: 150px;
}

table td:nth-child(8) {
    width: 150px;
}

table td:nth-child(9) {
    width: 70px;
}


table td:last-child {
    width: 70px;
}

.pagination {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.pagination a {
    cursor: pointer;
    background-color: #0C77BC;
    width: 35px;
    height: 35px;
    border-radius: 52%;
    transition-duration: 0.4s;
    color: #DEDEDE !important;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 6px;
    text-align: center;
    margin-right: 4px;
    font-weight: bold;
}

.pagination a:hover {
    background-color: #2297e6;
    color: white !important;
}


@media (max-width: 600px) {
    .mainn {
        width: 50em;
        margin-top: 10vh;
    }


}

.search-bar {
    float: right;
    width: 90%;
}

.search-label {
    float: right;
    margin-right: 3%;
    margin-top: 3%;
    font-size: 18px;
}


/*Loader style*/
.spinner {
    width: 90px;
    height: 90px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    -webkit-animation: spinner-e04l1k 1s infinite linear;
            animation: spinner-e04l1k 1s infinite linear;
    margin-left: 50%;
    margin-top: 5%;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }
  
  .spinner::before {
    border-color: #474bff #0000;
    -webkit-animation: inherit;
            animation: inherit;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    animation-direction: reverse;
  }
  
  .spinner::after {
    margin: 8.9px;
  }
  
  @-webkit-keyframes spinner-e04l1k {
    100% {
      -webkit-transform: rotate(1turn);
              transform: rotate(1turn);
    }
  }
  
  @keyframes spinner-e04l1k {
    100% {
      -webkit-transform: rotate(1turn);
              transform: rotate(1turn);
    }
  }

.my-modal {
    width: 75vw;
    /* Occupy the 90% of the screen width */
    max-width: 75vw;
}

.mod-title {
    margin-left: 2%;
}

.form-style {
    width: 100%;
}

.button-download {
    border-radius: 30px;
    color: white !important;
}

.card-term {
    height: 120px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    box-shadow: 0 10px 15px -3px rgba(66, 128, 180, 0.4), 0 4px 6px -4px rgba(33, 150, 243, .4);
    border-radius: 10px;
    cursor: auto;
}

.centered-svg {
    margin-left: 8%;
    margin-bottom: 5%;
}

.emailSvg{
    width: 100px;
    height: 80px;
    padding-bottom: 15%;
}

.card-det {
    color: white;
    height: 70%;
    grid-gap: .5em;
    gap: .5em;
    display: grid;
}

.text-b {
    text-align: center;
    color: white;
    font-weight: 800;
    font-size: 55px;

}

/*Text*/
.text-t {
    margin: 10px;
    font-weight: bold;
    text-align: center;
}

#card-t {
    background: #26c8e5;
    height: 200px;
}

#pago {
    background: #2ac52a;
}

#historial {
    background: rgb(16, 106, 136);
}

#notifi {
    background: #d7b348;
}

.card-term:hover {
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.table_edit th,
.table_edit td {
    width: 50%;
    /* O ajusta el porcentaje según tus necesidades */
}

.table_edit th,
.table_edit td {
    text-align: center;
}

.table_edit {
    max-height: 300px;
    overflow: auto;
    margin-top: 5%;
}

.btn-block {
    height: 100px;
    width: 250px;
    margin-bottom: 2%;
}

.margin-btn {
    margin-top: 8%;
}

.btn-down {
    margin-top: 35%;
    margin-left: 10%;
    position: absolute;
}

.card-cont {
    min-width: 158px;
    margin-top: 2%;
}

.sel2 {
    color: #fff;
    background-color: #04ccf5;
    display: inline-block;
    transition: all .5s;
    cursor: pointer;
    padding: 8px 7px !important;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    border-radius: 20%;
    height: 43px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}
.card_layout {
    width: 100%;
    padding-left: 25%; /* Ajusta el valor según tus necesidades */
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan en filas en pantallas más estrechas */

    overflow-y: auto; /* Permite el scroll vertical */
    max-height: 100vh; /* Restringe el contenedor al 100% de la altura visible */

  }
  
  @media (max-width: 1560px) {
    .card_layout {
      padding-left: 40%;
    }
  }
  
  @media (max-width: 805px) {
    .card_layout {
      padding-left: 15%;
    }
  }
  
  /* Resto de tus estilos CSS sin cambios */
  

.card-admin {
    width: 230px;
    height: 254px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    box-shadow: 0 10px 15px -3px rgba(66, 128, 180, 0.4),0 4px 6px -4px rgba(33,150,243,.4);
    border-radius: 10px;
   
}

#card-1{
    background: linear-gradient(45deg, #5e61c7ea 0%, #62aed6 100%);
}

#card-2{
    background: linear-gradient(45deg, #48a440ad 0%, #61cbcb 100%);
}

#card-3{
    background: linear-gradient(45deg, #d6aa3dad 0%, #d1cc28 100%);
}

#card-4{
    background: linear-gradient(45deg, #cd6150ad 0%, #cdaa2c 100%);
}

#card-5{
    background: linear-gradient(45deg, #5837c7ad 0%, #a92871 100%);
}

#card-6 {
    background: linear-gradient(45deg, #ff5733ad 0%, #ff914d 100%);
}

#card-7 {
    background: linear-gradient(45deg, #33aaffad 0%, #6699ff 100%);
}

#card-8 {
    background: linear-gradient(45deg, #ff33a2ad 0%, #ff66d9 100%);
}

#card-9 {
    background: linear-gradient(45deg, #33ffaaad 0%, #66ff99 100%);
}

#card-10 {
    background: linear-gradient(45deg, #aa33ffad 0%, #cc66ff 100%);
}


.card-details {
    color: white;
    height: 100%;
    grid-gap: .5em;
    gap: .5em;
    display: grid;
}

.card-button {
    -webkit-transform: translate(-50%, 125%);
            transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 1rem;
    border: none;
    background-color: #008bf8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
}

.text-b {
    text-align: center;
    color: white;
    font-weight: 800;
    font-size: 55px;
   
}

/*Text*/
.text-title {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
}

/*Hover*/
.card-admin:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.card-admin:hover .card-button {
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%);
    opacity: 1;
}
.searchName{
    margin-top: 12%;
}

.btnSearch{
    margin-top: 4%;
}

.infoAlert{
    margin: 2% 0 2% 0;
}

.printButtonReport{
    float: right;
    width: 18%;  
    -webkit-margin-end: 1%;  
            margin-inline-end: 1%;
}


.printButtonReport2{
    float: right;
    width: 28%;  
}
.billText {
    font-size: larger;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #000000;
    width: 70%;
}

.billText2 {
    font-size: larger;
    padding-left: 18%;
}

.custom-table thead th {
    background-color: #a2cefafe;

}

.autocomplete-options {
    max-height: 300px; /* Altura máxima de la lista */
    overflow: auto;   /* Habilita el desplazamiento si la lista excede la altura máxima */
    border: 1px solid #e9ecef;
    margin-top: 4px; /* Margen superior para separar del input */
    list-style-type: none; /* Elimina los marcadores de lista */
    padding: 0;
  }
  
  .autocomplete-option {
    padding: 5px;
    cursor: pointer;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
  }
  
  .autocomplete-option:hover {
    background-color: #dee2e6;
  }
  
  /* Solid border */
hr.solid2 {
  border-top: 3px solid #000000;
  width: 100%;
}
.cont{
    margin-top: 90px;
    margin-left: 50px;
    margin-right: 50px;
}

.btnW{
    font-size: x-large;
}

.user-img {
  max-width: 160px; /* Ancho máximo */
  max-height: 160px; /* Altura máxima */
  border-radius: 50%;
  text-align: center;
  border: 3px solid #d2d6de;
  padding: 3px;
  border-radius: 50%;
  vertical-align: center;
}


.supMargin{
    margin-top: 2%;
}

thead th {
    font-family: 'Inter', sans-serif;
    background-color: #dcdcdc !important
}

.customTbl{
    border-color: white;
}

.btnEditar{
    background-color: rgb(28, 153, 194);
    color: black;
    border-color:rgb(28, 153, 194);
}

.btnPago{
    background-color: rgb(245, 137, 49);
    color: black;
    border-color:rgb(245, 137, 49);
}

.btnCancelar{
    background-color: rgb(227, 69, 40);
    color: black;
    border-color:rgb(227, 69, 40);
}

.btnMonthTrans{
  background-color: rgb(103, 153, 223);
  color: black;
  border-color:rgb(103, 153, 223);
}

.btnReactivar{
    background-color: rgb(40, 227, 140);
    color: black;
    border-color:rgb(40, 227, 140);
}



.checkbox-container {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan en varias líneas si es necesario */
  justify-content: space-between; /* Espacio entre los elementos */
}

.checkbox-item {
  text-align: center; /* Centra el contenido horizontalmente */
  display: flex;
  flex-direction: column; /* Los elementos se colocarán en columna vertical */
  align-items: center; /* Centra los elementos horizontalmente */
  margin-right: 20px; /* Espacio entre los elementos (puedes ajustar según tu preferencia) */
  width: calc(33.33% - 20px); /* Ancho deseado para cada elemento (33.33% para 3 elementos en una fila) */
}

.container-check {
  text-align: center; /* Centra el contenido horizontalmente */
  --input-focus: #2d8cf0;
  --input-out-of-focus: #ccc;
  --bg-color: #fff;
  --bg-color-alt: #666;
  --main-color: #323232;
  position: relative;
  cursor: pointer;
}

/* Añade estilos al texto si es necesario */
.container-check span {
  display: block; /* Hace que el texto se muestre en una línea separada */
}

.container-check input {
  position: absolute;
  opacity: 0;
}

.checkmark {
  width: 40px;
  height: 40px;
  position: relative;
  top: 0;
  left: 0;
  border: 2px solid var(--main-color);
  border-radius: 5px;
  box-shadow: 1px var(--main-color);
  background-color: var(--input-out-of-focus);
  transition: all 0.3s;
}

.container-check input:checked ~ .checkmark {
  background-color: var(--input-focus);
}

.checkmark:after {
  content: "";
  width: 15px;
  height: 24px;
  position: absolute;
  top: 2px;
  left: 9px;
  display: none;
  border: solid var(--bg-color);
  border-width: 0 2.5px 2.5px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.container-check input:checked ~ .checkmark:after {
  display: block;
}

@media (max-width: 768px) {
  .checkbox-item {
    width: 100%; /* Hace que los elementos ocupen todo el ancho en dispositivos pequeños */
    margin-right: 0; /* Elimina el margen en dispositivos pequeños */
  }
}

/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 4em;
    height: 2em;
  }
  
  /* Hide default HTML checkbox */
  .switch .chk {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(255, 255, 255);
    transition: .4s;
    border-radius: 30px;
  }
  
  .slider:before {
    position: absolute;
    content: "INACTIVO";
    display: grid;
    place-content: center;
    height: 1.6em;
    width: 5.6em;
    border-radius: 20px;
    left: 0.17em;
    bottom: 0.22em;
    background-color: rgb(255, 0, 0);
    box-shadow: 0px 0px 3.7px black;
    transition: .4s ease-in-out;
  }
  
  .chk:checked + .slider:before {
    content: "ACTIVO";
    background-color: limegreen;
    box-shadow: inset 0px 0px 5px black;
  }

  #logo-reseller{
    align-items: center;
    height: 40px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.switch-2 {
    --input-focus: #2d8cf0;
    --bg-color: #fff;
    --bg-color-alt: #666;
    --main-color: #323232;
    --input-out-of-focus: #ccc;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
    width: 70px;
    height: 36px;
    -webkit-transform: translateX(calc(50% - 10px));
            transform: translateX(calc(50% - 10px));
  }
  
  .toggle {
    opacity: 0;
  }
  
  .slider-2 {
    box-sizing: border-box;
    border-radius: 100px;
    border: 2px solid var(--main-color);
    box-shadow: 4px 4px var(--main-color);
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--input-out-of-focus);
    transition: 0.3s;
  }
  
  .slider-2:before {
    content: "off";
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    position: absolute;
    left: 2px;
    bottom: 1px;
    border: 2px solid var(--main-color);
    border-radius: 100px;
    background-color: var(--bg-color);
    color: var(--main-color);
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: 25px;
    transition: 0.3s;
  }
  
  .toggle:checked + .slider-2 {
    background-color: var(--input-focus);
    -webkit-transform: translateX(-32px);
            transform: translateX(-32px);
  }
  
  .toggle:checked + .slider-2:before {
    content: "on";
    -webkit-transform: translateX(32px);
            transform: translateX(32px);
  }
.sonn3 {
    background: #FFFFFF;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px; /* Ajusta el espaciado interno según tus preferencias */
    border: 1px solid #E8EAEE;
  }

  
  .mainn3 {
    max-width: 2000px;
    width: 90%;
    margin: auto;
    margin-top: 2vw;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .buttonB {
    padding: 12.5px 30px;
    border: 0;
    border-radius: 100px;
    background-color: #2ba8fb;
    color: #ffffff;
    font-weight: Bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width:40%;
  }
  
  .buttonB:hover {
    background-color: #6fc5ff;
    box-shadow: 0 0 20px #6fc5ff50;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .buttonB:active {
    background-color: #3d94cf;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
.searchName{
    margin-top: 12%;
}

.btnSearch{
    margin-top: 4%;
    height: 40px;
}

.infoAlert{
    margin: 2% 0 2% 0;
}

.printButtonReport{
    float: right;
    width: 18%;  
    -webkit-margin-end: 1%;  
            margin-inline-end: 1%;
}


.printButtonReport2{
    float: right;
    width: 28%;  
}

.btnHeight {
    height: 35px;
}
