@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@1,400;1,700;1,900&display=swap');

* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;

}

body {
    background: #F1F1F1;
    font-family: sans-serif;
    margin: 0px;
}

.navbar {

    background: #FFFFFF !important;
    background-size: cover;
    top: 0px;
    position: fixed;
    z-index: 100;
    height: 50px;
    width: 100%;
    float: left;
}

main {
    max-width: 1800px;
    width: 90%;
    margin: auto;

    flex-wrap: wrap;
    display: flex;
    justify-content: center;


}

.son {
    background: #FFFFFF;

    width: 90em;
    border: 2px solid #E8EAEE;
    padding-top: 3px;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;
}

.son-hidden {
    opacity: 0.3;
}

.son1 {
    background: #FFFFFF;
    width: 90em;
    height: 41px;
    border: 1px solid #E8EAEE;

}

.son2 {
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding-inline-end: 3%;
    margin-top: 5%;
    width: 90em;

}

.footer {
    background: #FFFFFF;
    width: auto;
    height: 50px;
    align-self: flex-end;

}

nav ul {
    list-style: none;
    overflow: hidden;

}

nav ul li {
    float: left;
    font-family: Arial, sans-serif;
    font-size: 16px;
}

.DivForm {
    display: flex;
}

@media (max-width: 380px) {
    .DivForm {
        display: block;

    }

    .form-select {
        margin-bottom: 1vh;
    }

}

.DivFormState {
    margin-left: 2%;
}

.DivFormCC {
    width: 50%;
    margin-right: 2%;
}

.breadcrumbs {
    background-color: #E4E4E4;
    padding: 1vh;
    font-size: 0.7rem !important;
    float: right;
}

.images-dashboard {
    width: 3.5vw;

}

.body-btn {
    display: flex;
}

.images-btn {
    margin-left: 1vw;
    text-align: center;
    color: #3c8dbc;
    border-radius: 5px;
    width: 6vw;
}

@media (max-width: 600px) {
    .images-btn {
        width: 10vh;
    }

    .images-btn img {
        width: 12vw
    }


}

@media (max-width: 768px) {
    .images-btn {
        width: 10vh;
    }

    .images-btn img {
        width: 12vw
    }

    #error {
        grid-column: span 1;
    }


}

@media (max-width: 979px) {
    .images-btn {
        width: 10vh;
    }

    .spinner {
        visibility: hidden;
    }

}

.images-btn:hover {
    border: 1px #d1d1d1 solid;
    background-color: #fafafa;
}

a {
    text-decoration: none;
}

hr {
    color: #E4E4E4;

}

#card-txt-AcC {
    text-align: center;
}

.inputMask {
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 45px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid #B6BFCF;

}

.inputMask:focus-visible {
    outline: none;
}

.inputMask:focus {
    border: 1px solid #3C8DBC;
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
}


.inputCcNumber {
    width: 100%;
    background: #fff;
    border-radius: 3px;
    height: 34px;
    line-height: 34px;
    padding: 0 40px 0 10px;
    transition: .3s ease all;
    border: 1px solid #B6BFCF;
    margin-right: 2%;
}

.inputCcNumber:focus {
    border: 1px solid #3C8DBC;
    outline: none;
    box-shadow: 3px 0px 30px rgba(163, 163, 163, 0.4);
}

.table {
    margin-top: 2%
}

#card-title-db {
    display: flex;
    justify-content: space-between;
}

.form-select {
    margin-right: 1vw;
}

#btn-db {
    font-size: 14px;
    width: 15vh;

}

#form-group-credit-card {
    animation-duration: 1.2s;
    animation-name: slides-top;
}

#form-group-debitcard {
    animation-duration: 0.8s;
    animation-name: slides-top2;
}

#form-group-ath-movil {
    animation-duration: 0.8s;
    animation-name: slides-top2;
    text-align: center;
}

#form-group-ath-movil-formAddress {
    display: grid;
    animation-duration: 0.8s;
    animation-name: slides-top2;
    text-align: center;
}

#ATHMovil_Checkout_Button {
    margin-top: 5vh;
}

.ATHMovil_disabled {
    opacity: 0.5;
}

#ATHMreopen-modal {
    position: fixed !important;
}

.ATHMovil_fake {
    position: relative;
    top: -94px;
    width: 92%;
    height: 10vh;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    border: none;
    color: none;
}

.disappear {
    display: none;
    position: relative;
    top: -94px;
    width: 92%;
    height: 10vh;
    background-color: rgb(255, 255, 255);
    opacity: 0;
    border: none;
    color: none;
}

.QR_text {
    margin-top: 3vh;
    padding-bottom: -5vh;
}

#error {
    animation-duration: 0.5s;
    animation-name: error-animation;
}

.error-message {
    color: #FFEBEE;
}

.alert-formAdd {
    margin-top: 1vh
}

.fales {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.hhh {
    height: 100px !important;
    width: 100px !important;
}

.file-preview {
    font-size: 14px;
    width: 450px;
}

.sel {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: #106BA0;
    display: inline-block;
    transition: all .5s;
    cursor: pointer;
    padding: 15px 40px !important;
    text-transform: uppercase;
    width: fit-content;
    text-align: center;
    height: 80px;
    width: 260px;
    border-radius: 2%;
}

/* .spinner {
    position: absolute;
    left: 55vw;
    top: 55vh;
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    border-left-color: #0072BA;
  
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  } */

@keyframes error-animation {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


@keyframes slides-top {
    from {
        margin-left: 10%;
        width: 100%
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}

@keyframes slides-top2 {
    from {
        margin-left: 10%;
        width: 100%
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}



/**/

/* Estilos personalizados para los números de página */
/* Estilos para los elementos de paginación */
.pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
}

.pagination li {
    margin: 0 5px;
    ;
    padding: 5px 7px;
    border-radius: 0;
    /* Elimina la redondez de los elementos */
    cursor: pointer;
}

/* Estilo para la página actual */
.pagination li.active {
    background-color: #b1b3b6;
    /* Color de fondo para la página actual */
    color: #fff;
    /* Color de texto para la página actual */
}


.pagination a {
    border-radius: 0% !important;
}


.button2 {

    height: 34px;
    line-height: 45px;
    width: 40%;
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: .1s ease all;
    padding-bottom: 37px;
    margin-right: 2%;

    &:hover {
        box-shadow: 3px 0px 30px rgba(163, 163, 163, 1);
    }
}






/* Estilo del spinner2 */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Fondo oscuro con un poco de transparencia */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Coloca el modal por encima del contenido */
}

/* Estilo para el spinner */
.spinner2 {
    width: 90px;
    height: 90px;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
  }

.spinner2::before,
.spinner2::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
}

.spinner2::before {
    border-color: #474bff #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
}

.spinner2::after {
    margin: 8.9px;
}

.terms-text {
    font-size: 13px;           /* Tamaño de fuente legible */
    line-height: 1.6;          /* Espaciado entre líneas */
    color: #333;               /* Color oscuro para mejor contraste */
    text-align: justify;       /* Justificación para un aspecto ordenado */
}

