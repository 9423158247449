.sonn3 {
    background: #FFFFFF;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px; /* Ajusta el espaciado interno según tus preferencias */
    border: 1px solid #E8EAEE;
  }

  
  .mainn3 {
    max-width: 2000px;
    width: 90%;
    margin: auto;
    margin-top: 2vw;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    padding-top: 3%;
  }

  .buttonB {
    padding: 12.5px 30px;
    border: 0;
    border-radius: 100px;
    background-color: #2ba8fb;
    color: #ffffff;
    font-weight: Bold;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    width:40%;
  }
  
  .buttonB:hover {
    background-color: #6fc5ff;
    box-shadow: 0 0 20px #6fc5ff50;
    transform: scale(1.1);
  }
  
  .buttonB:active {
    background-color: #3d94cf;
    transition: all 0.25s;
    -webkit-transition: all 0.25s;
    box-shadow: none;
    transform: scale(0.98);
  }