.billText {
    font-size: larger;
}

/* Solid border */
hr.solid {
    border-top: 3px solid #000000;
    width: 70%;
}

.billText2 {
    font-size: larger;
    padding-left: 18%;
}

.custom-table thead th {
    background-color: #a2cefafe;

}

.autocomplete-options {
    max-height: 300px; /* Altura máxima de la lista */
    overflow: auto;   /* Habilita el desplazamiento si la lista excede la altura máxima */
    border: 1px solid #e9ecef;
    margin-top: 4px; /* Margen superior para separar del input */
    list-style-type: none; /* Elimina los marcadores de lista */
    padding: 0;
  }
  
  .autocomplete-option {
    padding: 5px;
    cursor: pointer;
    background-color: #f8f9fa;
    border: 1px solid #e9ecef;
  }
  
  .autocomplete-option:hover {
    background-color: #dee2e6;
  }
  
  /* Solid border */
hr.solid2 {
  border-top: 3px solid #000000;
  width: 100%;
}