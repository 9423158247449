#container-rp{
    min-height: 100vh;
    margin: auto;
}

#content-header-rp{
    padding-top: 10vh;
    font-size: 24px;
    font-weight: 500;
}
.content-header h1 {
    font-size: 24px;
    font-weight: 500;
}
.breadcrumbs{
    background: #E4E4E4;
    padding: 1vh;
    font-size: 0.7rem !important;
    float:right;
}
.link-rp{
    font-size: 12px;
    text-decoration: none; 
    color: #444;
}
.link-rp:hover{
    color: #444;
}

.icon{
    margin-right: 1vw;
}
#col-card-rp{
    margin-left: auto;
    margin-right: auto;
}
.content-btn-rp{
    margin-top: 15px;
    text-align: center;
}
#btn-rp{
    width: 100%;
}
hr{
    width: 100%;
}
h5{
    font-size: 18px;
    font-weight: 300;
}
.label_confpassw{
    margin-top: 2vh;
}
@media (max-width:600px) {
    #h1-grid{
        display: grid;
    }
}

/* Business info */

.div-form-bi{
    display: grid;
}
.div-form-bi label{
    margin-bottom: 2vh;
}
.div-form-bi input{
    margin-bottom: 2vh;
}
#btn1-bi {
    background-color: #f4f4f4;
    color: #444;
    border-color: #ddd;
    font-size: 12px;
}
#btn2-bi {
    background-color: #f4f4f4;
    color: #444;
    border-color: #ddd;
    font-size: 12px;
}

#certificado-bi{
    text-align: start;
    font-size: 12px;
}
#btn1-address{   
    font-size: 14px;
    margin-right: 0.2vw;
    margin-bottom: 1vh;
}
#btn2-address{
    font-size: 14px;
    
}
#btn3-address{
    font-size: 14px;
    margin: 0 0.2vw
}
#btn4-address{
    font-size: 14px;
}
@media (max-width: 1024px) {
    #btn1-address{   
        
    }
    #btn2-address{
        margin-bottom: 0.5vh;
        margin-right: 0.5vh;
    }
    #btn3-address{
        margin-bottom: 0.5vh;
        margin-right: 0.5vh;
    }
    #btn4-address{
        margin-bottom: 0.5vh;

    }
    
}
.div-agregarAddress{
    margin-top:2vh;
}
.btn-agregarAddress{
    background-color: white;
    margin-bottom: 2vh;
    padding: 0.5vw;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
#link-agregarAddress{
    font-size: 12px;
}
.form-group{
    margin-top: 2vh;
}
.form-group-files{
    display: flex;
    flex-direction: column;
    margin-top: 2vh;
}
.container-files-fa{
    display: grid;
    grid-template-columns: repeat(2,50%);
}
#btn-addAddress{
    margin-top:2vh;
    background-color: #3c8dbc;
}
.card{
    margin-top: 2vh;
}

#nombre-profile{
    text-align: center;
}

.profile-user-img{
    width: 50%;
    border-radius: 50%;
    text-align: center;
    border: 3px solid #d2d6de ;
    height: auto;
    margin: 0;
    padding: 3px;
    border-radius: 50%;
    vertical-align: center;
}

.imagen-profile{
    text-align: center;
}
.list-group-item{
    border-left: 0;
    border-right: 0;
    /* font-family: 'Source Sans Pro'; */
}
.email-profile{
    float: right;
    width: 100%;
}
b{
}
.form-control{
    margin-bottom: 1.5vh;
}
.form-group-principales{
    margin-top: 1vh;
    text-align: center;
}
#btnPrincipal{
    margin-right: 1vh;
}
@media (max-width:989px) {
    #btnPrincipal{
        margin-bottom: 0.5vh;
    }
}
.form-group-secundarios{
    text-align: center;
    animation-duration: 0.8s;
    animation-name: slides-top;
}
#btnSecundario{
    margin-right: 1vh;
}
#btnSecundario2{
    margin-right: 1vh;
}
#alePaswword{
    margin-top: 7vh;
}
#form-group-contraseñas{
    animation-duration: 0.8s;
    animation-name: slides-top;

}
#error{
    animation-duration: 0.5s;
    animation-name: error-animation;
}
.alert{
    animation-duration: 1s;
    animation-name: error-animation;
} 
@keyframes error-animation {
    from {
        opacity: 0;
      }
    
      to {
        opacity: 1;
      }
}
@keyframes slides-top {
    from {
        margin-top: 10%;
        width: 100%
      }
    
      to {
        margin-top: 0%;
        width: 100%;
      }
}
 a[href="#/"]{
     cursor: default;
 }

