.my-modal {
    width: 75vw;
    /* Occupy the 90% of the screen width */
    max-width: 75vw;
}

.mod-title {
    margin-left: 2%;
}

.form-style {
    width: 100%;
}

.button-download {
    border-radius: 30px;
    color: white !important;
}

.card-term {
    height: 120px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    box-shadow: 0 10px 15px -3px rgba(66, 128, 180, 0.4), 0 4px 6px -4px rgba(33, 150, 243, .4);
    border-radius: 10px;
    cursor: auto;
}

.centered-svg {
    margin-left: 8%;
    margin-bottom: 5%;
}

.emailSvg{
    width: 100px;
    height: 80px;
    padding-bottom: 15%;
}

.card-det {
    color: white;
    height: 70%;
    gap: .5em;
    display: grid;
}

.text-b {
    text-align: center;
    color: white;
    font-weight: 800;
    font-size: 55px;

}

/*Text*/
.text-t {
    margin: 10px;
    font-weight: bold;
    text-align: center;
}

#card-t {
    background: #26c8e5;
    height: 200px;
}

#pago {
    background: #2ac52a;
}

#historial {
    background: rgb(16, 106, 136);
}

#notifi {
    background: #d7b348;
}

.card-term:hover {
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.table_edit th,
.table_edit td {
    width: 50%;
    /* O ajusta el porcentaje según tus necesidades */
}

.table_edit th,
.table_edit td {
    text-align: center;
}

.table_edit {
    max-height: 300px;
    overflow: auto;
    margin-top: 5%;
}

.btn-block {
    height: 100px;
    width: 250px;
    margin-bottom: 2%;
}

.margin-btn {
    margin-top: 8%;
}

.btn-down {
    margin-top: 35%;
    margin-left: 10%;
    position: absolute;
}

.card-cont {
    min-width: 158px;
    margin-top: 2%;
}

.sel2 {
    color: #fff;
    background-color: #04ccf5;
    display: inline-block;
    transition: all .5s;
    cursor: pointer;
    padding: 8px 7px !important;
    width: fit-content;
    text-align: center;
    border-radius: 20%;
    height: 43px;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
}