.table {
    border-collapse: collapse;
    border: 1px solid black;
    text-align: center;
}

.tr1 {
    background-color: #616161;
    color: white;
    font-family: 'Inter', sans-serif;
}

.btn-descargar {
    background-color: #0D47A1;
    border-radius: 50%;
    border: 1px none;
    padding: 0.5vh;
    color: gainsboro
}

.mainn {
    max-width: 2000px;
    width: 99%;
    margin: auto;
    margin-top: 2vw;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    padding-top: 3%;

}

.sonn {
    background: #FFFFFF;

    width: 100em;
    border: 2px solid #E8EAEE;
    padding-top: 3px;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;


}

.struc {

    width: 100em;
    border: 2px solid #E8EAEE;

    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 2%;


}

.sonn1 {
    background: #FFFFFF;
    width: 100em;
    height: 41px;
    border: 1px solid #E8EAEE;

}

.titulo {
    color: #333333;
    font-size: 33px;
    margin-left: 1%;
}

.butF {
    background-color: #0C77BC;
    width: 35px;
    height: 25px;
    display: flex;
    border: none;
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10%;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    margin: 0 auto;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.editButton {
    border: none;
    background-color: white;
}

.icon1 {
    margin-left: 4%;
}

.butF:hover {
    background-color: #2297e6;
    color: white;
}

.icon {
    position: 50%;
}

tbody tr:nth-child(odd) {
    background-color: #F6F6F6;
    vertical-align: middle;
    align-items: center;

}

tbody tr:nth-child(even) {
    background-color: #DEDEDE;
    vertical-align: middle;
    align-items: center;
}

th {
    vertical-align: middle;
    align-items: center;
}

th.id {
    background-color: #616161;
    color: white;
    text-align: center;

}

table td:nth-child(6) {
    width: 120px;
}

table td:nth-child(7) {
    width: 150px;
}

table td:nth-child(8) {
    width: 150px;
}

table td:nth-child(9) {
    width: 70px;
}


table td:last-child {
    width: 70px;
}

.pagination {
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.pagination a {
    cursor: pointer;
    background-color: #0C77BC;
    width: 35px;
    height: 35px;
    border-radius: 52%;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    color: #DEDEDE !important;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 6px;
    text-align: center;
    margin-right: 4px;
    font-weight: bold;
}

.pagination a:hover {
    background-color: #2297e6;
    color: white !important;
}


@media (max-width: 600px) {
    .mainn {
        width: 50em;
        margin-top: 10vh;
    }


}

.search-bar {
    float: right;
    width: 90%;
}

.search-label {
    float: right;
    margin-right: 3%;
    margin-top: 3%;
    font-size: 18px;
}


/*Loader style*/
.spinner {
    width: 90px;
    height: 90px;
    display: grid;
    border: 4.5px solid #0000;
    border-radius: 50%;
    border-color: #dbdcef #0000;
    animation: spinner-e04l1k 1s infinite linear;
    margin-left: 50%;
    margin-top: 5%;
  }
  
  .spinner::before,
  .spinner::after {
    content: "";
    grid-area: 1/1;
    margin: 2.2px;
    border: inherit;
    border-radius: 50%;
  }
  
  .spinner::before {
    border-color: #474bff #0000;
    animation: inherit;
    animation-duration: 0.5s;
    animation-direction: reverse;
  }
  
  .spinner::after {
    margin: 8.9px;
  }
  
  @keyframes spinner-e04l1k {
    100% {
      transform: rotate(1turn);
    }
  }
