.offcanvasB{
    padding: 5px;
}

.btnToggler{
    cursor: pointer;
}

.title{
    font-size: larger;
    color: black;
}

.subtitle{
    font-size: medium;
    color: black;
}

.accordion-button{
    font-size: large;
    color: black;
}
