.card_layout {
    width: 100%;
    padding-left: 25%; /* Ajusta el valor según tus necesidades */
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se envuelvan en filas en pantallas más estrechas */

    overflow-y: auto; /* Permite el scroll vertical */
    max-height: 100vh; /* Restringe el contenedor al 100% de la altura visible */

  }
  
  @media (max-width: 1560px) {
    .card_layout {
      padding-left: 40%;
    }
  }
  
  @media (max-width: 805px) {
    .card_layout {
      padding-left: 15%;
    }
  }
  
  /* Resto de tus estilos CSS sin cambios */
  

.card-admin {
    width: 230px;
    height: 254px;
    border-radius: 20px;
    background: #f5f5f5;
    position: relative;
    padding: 1.8rem;
    border: 2px solid #c3c6ce;
    transition: 0.5s ease-out;
    overflow: visible;
    box-shadow: 0 10px 15px -3px rgba(66, 128, 180, 0.4),0 4px 6px -4px rgba(33,150,243,.4);
    border-radius: 10px;
   
}

#card-1{
    background: linear-gradient(45deg, #5e61c7ea 0%, #62aed6 100%);
}

#card-2{
    background: linear-gradient(45deg, #48a440ad 0%, #61cbcb 100%);
}

#card-3{
    background: linear-gradient(45deg, #d6aa3dad 0%, #d1cc28 100%);
}

#card-4{
    background: linear-gradient(45deg, #cd6150ad 0%, #cdaa2c 100%);
}

#card-5{
    background: linear-gradient(45deg, #5837c7ad 0%, #a92871 100%);
}

#card-6 {
    background: linear-gradient(45deg, #ff5733ad 0%, #ff914d 100%);
}

#card-7 {
    background: linear-gradient(45deg, #33aaffad 0%, #6699ff 100%);
}

#card-8 {
    background: linear-gradient(45deg, #ff33a2ad 0%, #ff66d9 100%);
}

#card-9 {
    background: linear-gradient(45deg, #33ffaaad 0%, #66ff99 100%);
}

#card-10 {
    background: linear-gradient(45deg, #aa33ffad 0%, #cc66ff 100%);
}


.card-details {
    color: white;
    height: 100%;
    gap: .5em;
    display: grid;
}

.card-button {
    transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 1rem;
    border: none;
    background-color: #008bf8;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
}

.text-b {
    text-align: center;
    color: white;
    font-weight: 800;
    font-size: 55px;
   
}

/*Text*/
.text-title {
    font-size: 23px;
    font-weight: bold;
    text-align: center;
}

/*Hover*/
.card-admin:hover {
    border-color: #008bf8;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.card-admin:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
}