/*********************Home*************/
.fila{
    display: flex;
    justify-content: center !important;
    align-items: center;
}

#div-content-h{
    display: flex;
    background-color: #f1f1f1;
    justify-content: center !important;
    align-items: center;

    
}
#div-container-h{
    padding-top: 10vh;
    margin-left: auto;
    margin-right: auto;
}
.card{
    display: flexbox;
    justify-content: center !important;
    width: 100%;
    font-size: 14px;
}
#div-card1-header-h{
    background-color: #f39c12;
    padding: 1vw;
}
#div-card1-header-h h5{
    margin-left: 4vw;
    color:white;
    font-weight: 300;
    font-size: 24px;
}
#div-card1-header-h h6{
    margin-left: 4vw;
    color:white;
    font-weight: 500;
}
#div-card2-header-h{
    background-color: #00a65a;
    padding: 1vw;
}
#div-card2-header-h h5{
    margin-left: 4vw;
    color:white;
    font-weight: 300;
    font-size: 24px;
}
#div-card2-header-h h6{
    margin-left: 4vw;
    color:white;
    font-weight: 500;
}
.pull-right{
    float: right;
}
#link-basic-h{
    background-color: #f39c12;
    width: 100%;
}
#link-pro-h{
    background-color: #00a65a;
    width: 100%;
}
#h4-col-h{
    font-size: 18px;
    font-weight: 500;
}
#div-hr-h{
    margin-top: 20px;
}
#div-col-md-6-h{
    font-size: 14px;
    font-weight: 400;
}
h3{
    font-size: 24px;
    font-weight: 500;
}
#div-contact-us-h{
    font-size: 14px;
}
iframe{
    border: 0;
}

/*********************Home*************/


/*********Carousel*********/
#Carousel-div{
    background-size: cover; 
    width: 100%; 
    height: 80vh;
}
@media (max-width: 600px) {
    #Carousel-div{
        margin-top: 11vh;
        height: 50vh;
    }
}
#Carousel-h1{
    color:white;
    padding-left: 10%;
    padding-top: 5%;
    font-size: 3.5vw;
    text-shadow: 1px 1px 5px #666;
}

/************Carousel**********/