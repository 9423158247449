footer{
    padding:15px; 
    font-size:14px; 
    border-top:1px solid #d2d6de; 
    background:#fff;
}

/************NavBar**********/

#main-header-nb{
    position: fixed;
    z-index: 100;
    background-color: white;
    width: 100%;
}

#logo-nb{
    margin-top: -2.3vh;
    position: relative;
    margin-left: 50%;
    align-items: center;
}
@media (max-width: 482px) {
    #logo-nb{
        margin-left: 0%;
        margin-right: 10%;
        width: 50%;
    }
}

#ul-nb{
    display: flex;
}
@media (max-width: 482px) {
    #ul-nb{
        display: block;
        margin-bottom: 1vh;
        margin-left: -19vh;
    }
    #div-ml{
        margin-top: -0.7vh;
    }
    #simple-menu-ml{
        width:15vh;
        margin-top: 0.4vh;
    }
    
}
.link-nb{
    font-size: 14px;
    color: rgb(85,85,85);
    text-decoration: none;
    margin-top: 0.3vh;
}
.link-nb:hover{
    color: rgb(85,85,85);
}
/************NavBar**********/
/***************** FormLogin ***************/
#div-email-fl{
    padding-left:20px; 
    padding-right:20px;
    margin: 15px;
}
#div-password-fl{
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 15px 15px 15px;
}
.icon-form{
    padding-right: 3px;
}
#div-login-fl{
    padding-left:20px;
    padding-right:20px; 
    margin-bottom:15px;
}
#btn-login-fl{
    width:100%; 
    border-color:#ddd; 
    /* background-color:#f4f4f4; */ 
    box-shadow:none; 
    border-width:1px;
}
#div-rp-fl{
    padding: 10px;
    font-size: 14px;
    background-color: white;
}
.img-user-login{
    height: auto;
    margin: 0;
    padding: 3px;
    border-radius: 50%;
    text-align: center;
}
.img-user-login img{
    border-radius: 50%;
    background: #FFF;
}
.container-user-login{
    text-align: center;
    background-color: #e5e5e5;
}
.info-user-login{
    text-align: center;
    margin-top: 2vh;
}
input {
    padding: 0.5vh;
}

@media (max-width:425) {
    .hidden-xs{
        display: flex;
        flex-flow: nowrap;
    }
    
}

/***************** FormLogin ***************/

/*************MenuLogin***************/
#div-ml{
    margin: 0 1vw;
}
@media (max-width: 440px) {
    #div-ml{
        margin-top: -0.7vh;
    }
    
}
#simple-menu-ml{
    font-size: 14px;
    color: rgb(85,85,85);
    text-decoration: none;
    cursor: pointer;
    display:inline-block;
}
/*************MenuLogin***************/